export class Configuration {
  public static Environment;
  public static NetworkId;
  public static PartnerId;
  public static PlatformUrl;
  public static PlatformUrl_v2;
  public static BasePath;
  public static TableManagerUrl;
  public static TournamentManagerUrl;
  public static NotificationServiceUrl;
  public static ClientUrl;
  public static clientPort;
  public static ClientAssetUrl;
  public static ClientAssetCommonUrl;
  public static ClientAssetHomePageUrl;
  public static ClientAssetMyAccountsPageUrl;
  public static ClientAssetPromotionsUrl;
  public static ClientAssetBankingUrl;
  // public static CloudFrontDomainUrl;
  public static LobbyUrl;
  public static GameRoomTabType;
  public static DefaultCashType;
  public static DefaultMainMenu;
  public static DefaultLanguage;
  public static GoogleClientId;
  public static GoogleApiKey;
  public static FacebookClientId;
  public static DeviceInfoRequired;
  public static DeviceCredentials;
  public static ExpiryBufferMinutes;
  public static DeviceExpiryTokenInMins;
  public static AccessExpiryTokenInMins;
  public static Languages;
  public static MyaccountsMenus;
  public static AboutUsFaqTermsMenus;
  public static ForgotPassowrdMenus;
  public static ChangeUsernameMenus;
  public static RummyPlayerProtectionAutoPlayMenus;
  public static ClubDetails;
  public static KycOptions;
  public static EKycOptions;
  public static EkycTextOptions;
  public static KycDocumentTypeOptions;
  public static HistoryFieldTypes;
  public static PromotionsCategory;
  public static WebsiteMainMenu;
  public static BankingMenus;
  public static PlayerDefaultAmounts;
  public static DepositBonus;
  public static InvalidPromotionTimeInSeconds;
  public static DepositPaymentTypes;
  public static PaymentDepositRedirectionUrl;
  public static WithdrawOTPTimeInSeconds;
  public static InternalSuccessMessageTimeInSeconds;
  public static WithdrawPaymentTypes;
  public static BankAccountTypes;
  public static WithdrawPaymentWallets;
  public static SuccessMessageTimeInSeconds;
  public static FailureMessageTimeInSeconds;
  public static SideMenus;
  public static footerMenus;
  public static footerRummyMenus;
  public static quickMenus;
  public static downloadMenu;
  public static apiError;
  public static DepositRetryTimerInSeconds;
  public static WithdrawRetryTimerInSeconds;
  public static WithdrawWaitingTimeInSeconds;
  public static DataTableColumns;
  public static RefreshTokenIntervalInSeconds;
  public static AccountHistoryDepositColumns;
  public static AccountHistoryLimits;
  public static AccountHistoryReconcileChipTypes;
  public static VerifyMobileRetryTimerInSeconds;
  public static PlayerLogsMenus;
  public static GameTypes;
  public static PlayerLogLimits;
  public static PlayerGameLogLimits;
  public static PlayerLogAvailableLimits;
  public static PlayerLogsHistoryColumns;
  public static ReferNEarnMenus;
  public static ReferNEarnContactsMenus;
  public static ReferNEarnContactsSuccessMenus;
  public static ReferChannelConfigs;
  public static LoggerUrl;
  public static LogLevels;
  public static RemoteLogEnabled;
  public static LogDisabled;
  public static ReduxLogDisabled;
  public static middlewareUrl;
  public static apk_link;
  public static meta_robots;
  public static console_enabled;
  public static support_link;
  public static hide_tournaments;
  public static hide_cashgames;
  public static allow_mobile_only_login;
  public static tds_withdraw_request;
  public static hidden_pages;
  public static enable_leaderboard_v1;
  public static theme_highlight_color;
  public static hide_freegames;
  public static disabled_payment_options;
  public static kyc_details_disabled;
  public static new_my_account_kyc_enabled;
  public static cdnBaseUrl;
  public static hidden_tabs;
  public static version;
  public static sid;
  public static custom_header;
  //public static IsUpiEnabled;
}
